import React from "react"
import { Link } from "gatsby"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Footer = ({}) => {
  return (
    <footer className="pt-4 pt-md-5 container-fluid">
      <Container>
        <Row>
          <div className="col-md">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 45" width="80" height="45" className="WebservisLogoFooter"><g transform="translate(0 -467)"><g transform="matrix(0.15625 0 0 0.15545115 0 449.70451)"><polygon points="344.9 400.7 512 111.3 376.1 111.3 276.9 283 " className="st0" className="a"/><polygon points="188 129.1 99.2 283 167.1 400.7 256 246.8 " className="st0" className="a"/></g><polygon points="276.9 283 376.1 111.3 0 111.3 99.2 283 188 129.1 " transform="matrix(0.15625 0 0 0.15545115 0 449.70451)" className="st1" fill="#00a4b6"/></g></svg>
          </div>
          <div className="col-md">
            <ul className="list-unstyled text-small">
              <li className="mb-1"><a className="link-secondary text-decoration-none text-white" href="/">Naslovna</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none text-white" href="/wordpress-sajt/">Sajt</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none text-white" href="/brzina/">Brzina</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none text-white" href="/odrzavanje/">Održavanje</a></li>
              <li className="mb-1"><a className="link-secondary text-decoration-none text-white" href="/kontakt/">Kontakt</a></li>
            </ul>
          </div>
          <div className="col-md">
            <h4 className="text-uppercase text-white">Kontakt informacije</h4>
            <div className="contact-info-container"><p className="address mb-0 text-white">
                ICT Hub Playground, Kralja Milana 10
              </p> <p className="mobile mb-0 text-white">
                Mobile: +381 63 7796918
              </p> <p className="email mb-0 text-white">
                Email: <a href="mailto:slavko.tepavcevic@webservis.rs" className="text-white">slavko.tepavcevic@webservis.rs</a></p> <p className="web mb-0 text-white">
                Web: <a href="/" className="text-white" aria-current="page">
                  webservis.rs
                </a></p></div>
          </div>
        </Row>
      </Container>
      
      <div className="footer-copyright text-center text-white py-3">
        &copy; 2021 Copyright
        <a href="/" className="text-white text-decoration-none" aria-current="page"> WebServis </a> | Sva prava zadržana |
        <a href="https://ekarte.rs/" className="text-white text-decoration-none" title="Avio karte" alt="Aviokarte"> eKarte.rs</a>
      </div>
    
    </footer>
  )
}

export default Footer
