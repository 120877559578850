import React, { useState } from "react"
import { useStaticQuery, graphql } from 'gatsby';
import Footer from "./Footer"
import Header from "./Header"
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';


const Layout = ({ children, bodyClass }) => {
  
    const {
        wp: { seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            sourceUrl
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                sourceUrl
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
            }
        }
    `);
  return (
    <SEOContext.Provider value={{ global: seo }}>
        <Container fluid className="pt-3">
          <Row>
            <Header className="container" />
            <main>
              {children}
            </main>
            <Footer />
          </Row>
        </Container>
    </SEOContext.Provider>
  )
}

export default Layout
