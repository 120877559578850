import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "./UniversalLink"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Menu = () => {
  const { wpMenu } = useStaticQuery(graphql`
    {
    wpMenu(id: {}) {
      name
      menuItems {
        nodes {
          label
          url
          databaseId
          connectedNode {
            node {
              ... on WpContentNode {
                uri
              }
            }
          }
        }
      }
    }
    }
  `)

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return (
        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
            const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url
  
            const itemId = "menu-item-" + menuItem.databaseId
  
            return (
                <UniversalLink
                  to={path}
                  id={itemId}
                  key={i + menuItem.url}
                  activeClassName={"current-menu-item current_page_item"}
                >
                  {menuItem.label}
                </UniversalLink>
            )
          })}
        </nav>
  )
}

export default Menu
